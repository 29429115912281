import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import moment from 'moment';

import {
  BulkUpdateContentMutationVariables,
  ContentApproval,
  ContentApprovalTypes,
  ContentAudit,
  GroupContentApprovalType,
  SearchContentSortFields,
  SortDirections,
  useBulkUpdateContentMutation,
  UserFeedFollowGroupFragment,
} from 'api';

import {
  useCapabilities,
  useContent,
  useCurrentUser,
  useFollowed,
  useNotify,
  useReleases,
} from 'hooks';
import * as paths from 'paths';

import { Date } from 'features/content/widgets';
import useBulkContentUpdateMutation from 'features/admin/updaters/useBulkContentUpdate';
import theme from 'theme';
import { isEmpty } from 'lodash';

interface Props {
  selectedGroup: UserFeedFollowGroupFragment;
  showRemove?: boolean;
}

const styles = {
  approve: {
    padding: theme.spacing(1, 2),

    '&:hover': {
      backgroundColor: 'secondary.light',
      color: 'primary.dark',
    },
  },
  edit: {
    padding: theme.spacing(1, 2),
    '&:hover': {
      backgroundColor: 'grey.300',
    },
  },
  remove: {
    padding: theme.spacing(1, 2),

    '&:hover': {
      backgroundColor: 'error.light',
      color: 'error.main',
    },
  },
  approvedIcon: {
    opacity: 0.5,
  },
  actions: {
    borderTop: `8px solid ${theme.palette.background.default}`,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
} as const;

const Approval: FC<Props> = ({ showRemove, selectedGroup }) => {
  const notify = useNotify();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const content = useContent();
  const isContentPage = useMatch(paths.content);
  const currentUser = useCurrentUser();
  const params = useParams<{ group_id: string }>();
  const releases = useReleases();
  const { isAdmin } = useCapabilities();
  const { owned, moderated } = useFollowed();

  const { approval, audit, content_id, publish_at, primary_group_id } = content;
  const isScheduled = approval === ContentApproval.Submitted && !!content?.schedule?.publish;
  const has4EyeModeration = releases.includes('4EyeModeration');
  const { group_id } = params;
  const groupId = group_id || primary_group_id;
  const group = content.groups.find(group => group.id === groupId);
  const scheduledTime = moment.unix(content?.schedule?.publish).format('LL [at] h:mm A');

  const [clicked, setClicked] = useState<'approval' | 'removal'>(null);
  const createdByAdminOrModerator =
    audit.find(aud => aud.ACTION === 'four_eyes_created') || ({} as ContentAudit);
  const lastAudit = audit[audit.length - 1] || ({} as ContentAudit);
  const approvalCount = Number(lastAudit?.message) || 0;
  const isApprovedByYou = approvalCount === 1 && lastAudit?.by_user_id === currentUser.id;
  const is4EyesModeration = group?.content_approval_type === GroupContentApprovalType.FourEyes;
  const isCreatedByYou = content.user_id === currentUser.id;
  const isModerator = !![...moderated, ...owned].filter(
    (group: UserFeedFollowGroupFragment) => group.id === groupId
  )?.length;
  const isOnlyAdmin = isAdmin && !isModerator;

  const variables: BulkUpdateContentMutationVariables = {
    bulk_update: {
      approval: ContentApprovalTypes.Submitted,
      content_group_list: [
        {
          group_id: selectedGroup.id,
          content_id: content_id,
        },
      ],
    },
  };

  const updateContentMutationOptions = useBulkContentUpdateMutation({
    search_content: {
      approval: showRemove ? ContentApprovalTypes.Submitted : ContentApprovalTypes.Removed,
      group_list: [selectedGroup.id],
      sort_by: [
        {
          direction: SortDirections.Desc,
          field: SearchContentSortFields.PublishAt,
        },
      ],
    },
  });

  const { mutate: updateContentMutation, isLoading } = useBulkUpdateContentMutation({
    ...updateContentMutationOptions,
    onSuccess,
    onError: () => setClicked(null),
  });

  function onSuccess() {
    setClicked(null);

    if (
      variables.bulk_update.approval === ContentApprovalTypes.Approved ||
      variables.bulk_update.approval === ContentApprovalTypes.Submitted
    ) {
      notify.info({ message: t('content:postApproved') });
      isContentPage && window.location.reload();
      return;
    }

    notify.info({ message: t('content:postRemoved') });
    isContentPage && window.location.reload();
  }

  function handleEdit() {
    return navigate(`/post/edit/${content_id}`);
  }

  function handleContentRemoval() {
    setClicked('removal');
    variables.bulk_update.approval = ContentApprovalTypes.Removed;
    updateContentMutation(variables);
  }

  function handleContentApproval() {
    setClicked('approval');

    if (has4EyeModeration && is4EyesModeration) {
      variables.bulk_update.approval = ContentApprovalTypes.Submitted;
      updateContentMutation(variables);
      return;
    }

    variables.bulk_update.approval = ContentApprovalTypes.Approved;
    updateContentMutation(variables);
  }

  function renderButtons() {
    if (isScheduled) {
      return null;
    }

    if (has4EyeModeration && is4EyesModeration) {
      if (isApprovedByYou) {
        return (
          <Grid container justifyContent="center" alignItems="center" marginTop={1}>
            <Grid marginRight={1} display="flex" alignItems="center" justifyContent="center">
              <CheckCircleRoundedIcon color="primary" sx={styles.approvedIcon} />
            </Grid>

            <Typography variant="body2" color={`${theme.palette.primary.main}`}>
              {t('content:approvedByYou')}
            </Typography>
          </Grid>
        );
      }

      if (isCreatedByYou) {
        return (
          <Typography variant="body1" color={`${theme.palette.error.main}`} margin={1}>
            {t('content:fourEyesModerationOnCreator')}
          </Typography>
        );
      }
    }

    return (
      <Grid container>
        {showRemove && (
          <Grid>
            <Button
              variant="text"
              startIcon={<CancelIcon color="error" />}
              onClick={handleContentRemoval}
              disabled={isLoading || (has4EyeModeration && is4EyesModeration && isOnlyAdmin)}
              sx={styles.remove}
            >
              {t('common:remove')}
              {isLoading && clicked === 'removal' && (
                <Box marginLeft={1} display="flex" justifyContent="center">
                  <CircularProgress color="error" size={16} />
                </Box>
              )}
            </Button>
          </Grid>
        )}
        <Grid>
          <Button
            variant="text"
            startIcon={<EditIcon />}
            onClick={handleEdit}
            sx={styles.edit}
            disabled={isLoading}
          >
            {t('common:edit')}
          </Button>
        </Grid>
        {showRemove && (
          <Grid>
            <Button
              variant="text"
              startIcon={<CheckCircle color="primary" sx={isOnlyAdmin && { opacity: 0.5 }} />}
              onClick={handleContentApproval}
              disabled={isLoading || (has4EyeModeration && is4EyesModeration && isOnlyAdmin)}
              sx={styles.approve}
            >
              {t('common:approve')}
              {isLoading && clicked === 'approval' && (
                <Box marginLeft={1} display="flex" justifyContent="center">
                  <CircularProgress color="primary" size={16} />
                </Box>
              )}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Fragment>
      <CardContent>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid xs>
            <Date contentId={content_id} publishAt={publish_at} />
          </Grid>

          {renderButtons()}
        </Grid>
      </CardContent>

      {has4EyeModeration && is4EyesModeration && (
        <CardActions sx={styles.actions}>
          <Typography variant="body2" color={`${theme.palette.error.main}`} padding={0.8}>
            {!isEmpty(createdByAdminOrModerator)
              ? t('content:moderatorAdminApprovalCount', { count: Number(approvalCount) })
              : t('content:approvalCount', { count: Number(approvalCount) })}
          </Typography>
        </CardActions>
      )}

      {isScheduled && (
        <CardActions sx={styles.actions}>
          <Typography color="textSecondary">
            {t('content:scheduledTime', { time: scheduledTime })}
          </Typography>
        </CardActions>
      )}
    </Fragment>
  );
};

Approval.defaultProps = {
  showRemove: false,
};

export default Approval;
